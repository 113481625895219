import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import {
    Nav,
    Navbar,
    NavDropdown,
    Col,
    Row,
    Container,
    Dropdown,
  } from "react-bootstrap";
  import { Link } from "react-router-dom";  
  import Properties from '../properties.js';

const CategoryMegamenu = (props) => {

return (
    <>
    {/* {console.log("courseList===>",props.courseList)} */}
        
        {/* <div className="CoursesNav"> */}
            <div class="desktop_menu">
                <div class="mt-4">
                    <div class="row">
                        {props.courseList.map((item,index) => (
                            
                                    <div class="col-2">
                                        <div
                                            className={`category ${(index + 1) % 6 === 0 ? "last-in-row" : ""}`}
                                            >
                                            <Link to={Properties.BASE_URL+item.details.cat_slug}>
                                                <img  src={item.details.cat_image} /> 
                                                <div class="cat">
                                                {item.details.cat_name}
                                                </div>
                                            </Link>
                                            <div class="submenu">
                                            {item.details.course?.length && item.details.course.map((course,index) => (
                                                course.name != null && 
                                                <div class="cname"><Link to={Properties.BASE_URL+item.details.cat_slug+"/"+course.course_slug}>{course.name}</Link></div>
                                            ))}
                                            </div>
                                        </div>
                                    </div>
                                
                        ))}
                    </div>
                </div>
            </div>
                <div class="mob_menu">

                    <section class="nav-wrap">
                        <div class="container">
                        <nav class="acnav" role="navigation">
                        
                            <ul class="acnav__list acnav__list--level1">
                            {props.courseList.map((item , index) => (
                                <li class="has-children">
                                    <input id={`group-${index}`} class="acnav__checkbox" type="checkbox" hidden />
                                    
                                        <label class="acnav__label" for={`group-${index}`}>
                                            <Link to={Properties.BASE_URL+item.details.cat_slug}>
                                                <img src={item.details.cat_image} /> 
                                                {item.details.cat_name}
                                            </Link>
                                        <i class="fa fa-sort-desc" aria-hidden="true"></i>
                                        </label>
                                    
                                    <ul class="acnav__list acnav__list--level2">
                                        {item.details.course?.length && item.details.course.map((course,index) => (
                                            <li>
                                                <Link to={Properties.BASE_URL+item.details.cat_slug+"/"+course.course_slug}>{course.name}</Link>
                                            </li>
                                        ))}

                                        
                                    </ul>
                                </li>                       
                            ))}
                            </ul>
                            
                        </nav>
                    </div>   
                    </section>

                    </div>
         
         {/* </div> */}
    </>
);
}
export default CategoryMegamenu;