import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import {GetCms} from '../services/Api'
import Properties from '../properties.js';
import DelayedComponent from "./DelayedComponent";

const Cmspage = (props) => {
    const [cmsData, setCmsMode] = useState([]); 
    const [pageData, setCmsPageData] = useState([]); 
    const [getTitle, setTitle] = useState([]); 
    const [getPageMetaDes, setPageMetaDes] = useState([]);
    const [loading, setLoading] = useState(true);

    const location = useLocation(); 
    const GetCmsData = async () => {
        await GetCms(props.slug).then(cmsresponse => {
            console.log("cmsresponse",cmsresponse);
           if (cmsresponse.status===200) {
            setCmsMode(cmsresponse.data);
            setCmsPageData(JSON.parse(cmsresponse.data?.page_details));       
           }
        })
    }
    useEffect(() => {
        setLoading(true);
        GetCmsData();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        window.scrollTo(0, 0);
    }, [location,cmsData?.length]);
    
  return (<>
  {(loading)?<DelayedComponent delay={1000}/>:(
      <>
  {props.categoryList.length>0 && <Header clsName={"inner-header"}  mataTitle={pageData?.meta_title || ''} metaDes={pageData?.meta_desc || ''} categoryList={props.categoryList} />}
    <div className="inner_banner_wrapper clr" id="cmsId" style={{ background: 'url("'+Properties.IMAGE_PATH+'public/gallery/MAIN%20IMAGE.png")' }}>
      
    <h1>{pageData?.banner_head}</h1>

 </div>
    <section className="catabox py-5 cmspgcls">
  <div className="container"  dangerouslySetInnerHTML={{ __html: pageData?.description }}>
    
  </div>
</section>
<Footer />
</>
    )}
</>
  )
}

export default Cmspage