
import React, { useState, useEffect,useCallback } from 'react';
import Header from './Header';
import Footer from './Footer';
import {GetCourseByCat} from '../services/Api';
import Properties from '../properties.js';
import { Link, useLocation } from "react-router-dom";

const Categories = (props) => {

  //console.log(props);
  const [activeTab, setActiveTab] = useState(props.slug);
  const [metaTitle, setMetaTitle] = useState();
  const [metaDes, setMetaDes] = useState();
  //const [tabList, setTabList] = useState(props.CourseData);
  const tabList = props.CourseData;
  const location = useLocation(); 

  //console.log(tabList);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    getSeoData(tabId);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const getSeoData = (curTab) => {
    //console.log("tabList", tabList);
    const seoData = tabList.filter(item => item.details.cat_slug === curTab);
    //console.log(seoData);
    if (seoData?.length) {
      seoData.map((item, index) => {
        setMetaTitle(item.details.cat_name);
        setMetaDes(item.details.cat_name);
      });
    }
  };
  // [tabList, props.slug, setMetaTitle, setMetaDes]


  useEffect(() => {
   handleTabClick(props.slug);  
  },[location]); // empty dependency array means this effect will only run once after the component mounts





  
  return (
<>
{/* {console.log("CategoryPage Props=====",props.categoryList)} */}
    { tabList?.length && <Header clsName="inner-header" mataTitle={metaTitle} metaDes={metaDes}  categoryList={props.categoryList} />}
  <div
    className="inner_banner_wrapper"
    style={{ background: 'url("'+Properties.IMAGE_PATH+'public/gallery/MAIN%20IMAGE.png")' }}
  >
    <h1>Categories &amp; Courses</h1>
  </div>
  <section className="py-5">
    <div className="container py-4 catabox">
    <div class="bredcum"><a href="/"><i class="fa-solid fa-arrow-left-long"></i> Back to Home</a></div>
    <div className="row">
      <div className="col-md-4 p-0">
        <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
       {/* { console.log("suva======>",tabList)} */}

        {tabList?.length && tabList.map((item,index) => (        
                <a
                    key={index} // assuming each item has a unique identifier
                    className={`nav-link p-3 ${activeTab === item.details.cat_slug ? 'active' : ''}`}
                    onClick={() => handleTabClick(item.details.cat_slug)}
                    role="tab"
                >
                    <img src={item.details.cat_image} alt="" />
                    <span className="font-weight-bold small text-uppercase">{item.details.cat_name}</span>
                </a>
            ))}

        </div>
      </div>
      <div className="col-md-8 p-0">
        <div className="tab-content-tab" id="v-pills-tabContent">
        {tabList?.length && tabList.map((item,index) => (  
          // console.log(item),
          <div key={index} className={`tab-pane fade ${activeTab === item.details.cat_slug ? 'show active' : ''}`} id="ai-industrial-markets" role="tabpanel">
              <div className="heading">{item.details.cat_name}</div>
              {/* <p>{item.details.cat_name}</p> */}
              <div className="rt_sec">
               
                  <div className="small_heading">Courses</div>
                  <ul className="m-list">
                  {item.details.course?.length && item.details.course.map((course,index) => (
                    course.name != null && 
                      <li key={index}><Link to={Properties.BASE_URL+item.details.cat_slug+"/"+course.course_slug}>{course.name} <i className="fa-solid fa-arrow-right-long"></i></Link> </li>
                  ))}
                  </ul>
               </div>
          </div>
        ))}
          
        </div>
      </div>
    </div>
    </div>
  </section>
    <Footer />
</>

  )
}

export default Categories