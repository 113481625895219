import React,{ useState,useEffect,useContext }  from 'react'
import { Link } from 'react-router-dom'
import Properties from '../properties.js';
import {DeliveryPostData} from '../services/Api'
import OwlCarousel from 'react-owl-carousel';
import { AccreditationContext } from './Accreditation';
const Footer = (props) => {
   const baseUrl = window.location.origin;  
   const [formData, setFormData] = useState({
      fname: '',
      lname: '',
      email: '',
      subject: '',
      phone_no: '',
      message: '',
    });
   const [isSubmitting, setIsSubmitting] = useState(false); 
   const [isMailsent, setMailsent] = useState(false); 
   const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
  
    // Function to handle form submission
    const handleSubmit = async (event,slug) => {
      event.preventDefault();
      setIsSubmitting(true);
      await DeliveryPostData(formData).then(data => {
         if (data.status === 200) {
            setIsSubmitting(false);
            setFormData({
               fname: '',
               lname: '',
               email: '',
               subject: '',
               phone_no: '',
               message: '',
             });
             setMailsent(true);
             setTimeout(() => {
               setMailsent(false);
             }, 7000);
            //setDeliverystate(false);
            //setDeliverystateSlug(slug);
         }
       })
      // You can send form data to an API, or perform any other action
    }; 
    const accreditation = useContext(AccreditationContext);

    console.log('accreditation',accreditation);
  return (
   <>
   <section id="acr">
         <div className="container">
            <div className="testimonial-section" data-aos="zoom-in">
               <OwlCarousel
               items={10}
               loop={true}
               nav={false}
               autoplay={true}
               dots={false}
               
               className="owl-carousel owl-theme">
                  {accreditation?.accreditationimage?.length && accreditation?.accreditationimage?.map((item, index) => (
                     <div className="each-testi global-content" key={index}>
                        <span>
                              <img src={Properties.BACKEND_IMAGE_PATH + item} alt={`Accreditation ${index + 1}`} loading="lazy" />
                        </span>             
                     </div>
                  ))}
                  
               </OwlCarousel >
            </div>
         </div>
      </section>
    
    {/* <div className={props?.classNm}> */}
    <div>
      <div className='whatsapp'>
         <div class="plush_02">    
               <a href="https://wa.me/442039831447" target="_blank" class="show_box btn btn-sm ">
                  <img src={Properties.IMAGE_PATH+`public/gallery/whatsapp.png`} alt="call" />
               </a>
            </div>
         </div>
         
         <div className="footer">
         <div className="container">
            <div className="ft-header">
               <img src={Properties.IMAGE_PATH+`public/gallery/logo.png`} alt="" />
            </div>
            <div className="row">
               <div className="col-md-4 col-sm-12">
                  <div className="con_sc">
                     <h5>Contact Us</h5>
                     <p>We can’t wait to hear from you. </p>
                     <p>Embark on your journey to mastering AI with AiinoX!</p>
                  </div>
                  <div className="add_sec">
                     <div className="add_wrp">
                        <strong> AiinoX Ltd</strong>  <br />
                      
                        Grove House,<br />
                        55 Lowlands Rd,<br />
                        Harrow<br />
                        HA1 3AW<br />
                        +44 (0) 203 983 1447 <br  />
                        <a href="mailto:info@aiinox.com">  info@aiinox.com</a> 
                        
                     </div>
                     <div className="add_wrp">                         
                        <div className="">
                           <span>Operating Hours:</span><br />
                           Mon – Fri: 9am – 5pm <br />
                           Sat & Sun: Online support only
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-6 col-sm-12">
                  <div className="con_sc">
                     <h5>Information Request</h5>
                  </div>
                  <form onSubmit={(event) => handleSubmit(event)}>
                     <div className="row input-container">
                        <div className="col-md-6 col-sm-12">
                           <div className="styled-input wide">
                              <input type="text" name="fname" required value={formData.fname} onChange={handleInputChange}   placeholder='First Name'/>
                              
                           </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                           <div className="styled-input wide">
                              <input type="text"  name="lname" required value={formData.lname} onChange={handleInputChange}  placeholder='Last Name'/>
                           </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                           <div className="styled-input wide">
                              <input type="text"  name="email" required value={formData.email} onChange={handleInputChange}  placeholder='Email'/>
                              
                           </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                           <div className="styled-input wide">
                              <input type="text"  name="phone_no" required value={formData.phone_no} onChange={handleInputChange}  placeholder='Phone Number'/>
                           
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="styled-input wide">
                              <input type="text"  name="subject" required value={formData.subject} onChange={handleInputChange}  placeholder='Subject'/>
                           
                           </div>
                        </div>
                        <div className="col-sm-12">
                           <div className="styled-input wide">
                              <textarea  name="message" required value={formData.message} onChange={handleInputChange}  placeholder='Message'></textarea>
                           </div>
                        </div>
                        <div className="col-xs-12">
                           <div className="row">
                              <div className="col-sm-3">
                                 {isSubmitting ? (
                                    <button type="submit" className="btn-lrg submit-btn" disabled>
                                       Sending...
                                    </button>
                                 ) : (
                                    <button type="submit" className="btn-lrg submit-btn">
                                       Submit
                                    </button>
                                 )}
                              </div>
                              <div className="col-sm-9 submsg">
                              {isMailsent && (
                                 <p>Thanks for reaching out! Your message has been received.</p>
                              )}
                              </div>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
            <div className="foter-btm">
               <div className="row">
                  <div className="col-md-6 col-sm-12">
                     <p>@2025 AiinoX Ltd is a company registered in England and Wales, Company number 16262264.</p>
                  </div>
                  <div className="col-md-4 col-sm-12">
                     <ul className="list-nav">
                        <li><Link to={`${baseUrl}/privacy-and-cookies-policy`}>Privacy and Cookies Policy</Link> </li>
                        {/* <li><Link to={`${baseUrl}/cookie-policy`}>Cookie policy</Link></li> */}
                        <li><Link to={`${baseUrl}/terms-conditions`}>Terms & conditions</Link></li>
                     </ul>
                  </div>
                  <div className="col-md-2 col-sm-12">
                     <ul className="list-nav sc">
                        <li><a href="https://www.facebook.com/ukaiinox" target='blank'><img style={{height:'18px'}} src={Properties.IMAGE_PATH+`public/gallery/fb.png`} /></a> </li>
                        <li><a href="https://x.com/aiinoxuk" target='blank'><img src={Properties.IMAGE_PATH+`public/gallery/X.svg`} /></a> </li>
                        <li><a href="https://www.instagram.com/aiinox_uk/" target='blank'><img src={Properties.IMAGE_PATH+`public/gallery/IG.svg`} /></a></li>
                        <li><a href="https://linkedin.com/company/aiinox-uk" target='blank'><img src={Properties.IMAGE_PATH+`public/gallery/LINKEDIN.svg`} /></a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
    </>
  )
}

export default Footer