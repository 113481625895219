import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import DeliveryMode from './DeliveryMode';
import BusinessConsultation from './BusinessConsultation';
import {GetCms} from '../services/Api'
import Properties from '../properties.js';
import {GetDelivery,DeliveryPostData} from '../services/Api'
import DelayedComponent from "./DelayedComponent";

const Service = (props) => {
  const [deliveryData, setDeliveryMode] = useState([]);
    const [cmsData, setCmsMode] = useState([]); 
    const [pageData, setCmsPageData] = useState([]); 
    const [getTitle, setTitle] = useState([]); 
    const [getPageMetaDes, setPageMetaDes] = useState([]);
    const [deliveryState, setDeliverystate] = useState(false); 
   const [deliveryStateSlug, setDeliverystateSlug] = useState(false);
   const [loading, setLoading] = useState(true);

    const location = useLocation();
    const [formData, setFormData] = useState({
      fname: '',
      lname: '',
      email: '',
      delivery_mode: '',
      phone_no: '',
      message: '',
    });
   const [isSubmitting, setIsSubmitting] = useState(false);  
    const GetCmsData = async () => {
        await GetCms(props.slug).then(cmsresponse => {
            console.log("cmsresponse",cmsresponse);
           if (cmsresponse.status===200) {
            setCmsMode(cmsresponse.data);
            setCmsPageData(JSON.parse(cmsresponse.data?.page_details));       
           }
        })
    }
    const DeliveryClick = (event,slug) => {
      event.preventDefault();
      console.log("click delevery mode",slug); 
      setDeliverystate(true); 
      setDeliverystateSlug(slug);
    }
    const GetDeliveryData = async () => {
      await GetDelivery('all').then(deliveryresponse => {
         if (deliveryresponse.status===200) {
            //console.log(" Del Data",deliveryresponse.data)
            setDeliveryMode(deliveryresponse.data)         
         }
      })
    }
    const handlePopupClose = (slug) =>{
      //console.log("slug=====>",slug)
      setDeliverystate(false);
      setDeliverystateSlug(slug);
    }
    useEffect(() => {
        GetCmsData();
    }, [location,cmsData?.length]);
    useEffect(() => {
      GetDeliveryData(); 
  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (event,slug,delivery_mode) => {
    event.preventDefault();
    setIsSubmitting(true);
    const updatedFormData = { ...formData, delivery_mode };
    setFormData(updatedFormData);
    await DeliveryPostData(updatedFormData).then(data => {
       if (data.status === 200) {
          setIsSubmitting(false);
          setDeliverystate(false);
          setDeliverystateSlug(slug);
       }
     })
    // You can send form data to an API, or perform any other action
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); 
  }, [location,cmsData?.length]);
  return (<>
  {(loading)?<DelayedComponent delay={1000}/>:(
      <>
  {props.categoryList.length>0 && <Header clsName={"inner-header"}  mataTitle={pageData?.meta_title || ''} metaDes={pageData?.meta_desc || ''} categoryList={props.categoryList} />}
    <div className="inner_banner_wrapper clr dfd" id="cmsId" style={{ background: 'url("'+Properties.IMAGE_PATH+'public/gallery/MAIN%20IMAGE.png")' }}>
      
    <h1>{pageData?.banner_head}</h1>
    </div>
    <section className="py-5">
    
<div className="container">
<div class="bredcum"><a href="/"><i class="fa-solid fa-arrow-left-long"></i> Back to Home</a></div>
                <BusinessConsultation infomationreq={DeliveryClick} cmsData={pageData} />
</div>
</section>
<Footer />
<DeliveryMode DeliveryData={deliveryData} DelSlug={deliveryStateSlug} handlePopupClose={handlePopupClose} formData={formData} isSubmitting={isSubmitting} handleSubmit={handleSubmit} handleInputChange={handleInputChange} DelState={deliveryState} />
</>
    )}
</>
  )
}

export default Service