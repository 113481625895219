import React, { useState,useEffect } from 'react'
import $ from "jquery";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './Header';
import Footer from './Footer';
import Search from './Search';
import DeliveryMode from './DeliveryMode';
import {GetDelivery,DeliveryPostData,GetCms} from '../services/Api'
import Properties from '../properties.js';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Home = (props) => {
   const [deliveryData, setDeliveryMode] = useState([]); 
   const [deliveryState, setDeliverystate] = useState(false); 
   const [deliveryStateSlug, setDeliverystateSlug] = useState(false); 
   const [cmsData, setCmsMode] = useState([]); 
   const [pageData, setCmsPageData] = useState([]);
   const [formData, setFormData] = useState({
      fname: '',
      lname: '',
      email: '',
      delivery_mode: '',
      phone_no: '',
      message: '',
    });
   const [isSubmitting, setIsSubmitting] = useState(false); 
   const location = useLocation();
   const navigate = useNavigate();

   useEffect(() => {
      $(".box").on({
         mouseover:function(){
            $(this).find("img:nth-child(1)").stop().animate({opacity:0},600);
            $(this).find("img:nth-child(2)").stop().animate({opacity:1},600);
         }, mouseout:function(){
            $(this).find("img:nth-child(1)").stop().animate({opacity:1},600);
            $(this).find("img:nth-child(2)").stop().animate({opacity:0},600);
         } 
      });

      
      //setCatName(props.categoryList);
      
      GetDeliveryData();      
    },[]);

    useEffect(() => {
      if (location.hash)
         window.history.pushState({}, '', location.pathname.replace("#" + location.hash,""));

    },[location.hash])

    const showAlert = (text) => {
      Swal.fire({
        title: '',
        text: text,
        icon: 'success',
        confirmButtonText: 'Close'
      });
    };

    const GetDeliveryData = async () => {
      await GetDelivery('all').then(deliveryresponse => {
         if (deliveryresponse.status===200) {
            //console.log(" Del Data",deliveryresponse.data)
            setDeliveryMode(deliveryresponse.data)         
         }
      })
   }
    const DeliveryClick = (event,slug) => {
      event.preventDefault();
      //console.log("Delvery click===>",slug)
      if(slug==="classroom-training")
         navigate('/artificial-intelligence-ai');
      else if(slug==="onsite-instructor-led-training")
         navigate('/business-consultation');
      else
         setDeliverystate(true); 
         setDeliverystateSlug(slug);
    }
    const handlePopupClose = (slug) =>{
      //console.log("slug=====>",slug)
      setDeliverystate(false);
      setDeliverystateSlug(slug);
    } 

    
    // Function to handle changes in form inputs
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
  
    // Function to handle form submission
    const handleSubmit = async (event,slug,delivery_mode) => {
      event.preventDefault();
      setIsSubmitting(true);
      const updatedFormData = { ...formData, delivery_mode };
      setFormData(updatedFormData);
      await DeliveryPostData(updatedFormData).then(data => {
         if (data.status === 200) {
            setIsSubmitting(false);
            setDeliverystate(false);
            setDeliverystateSlug(slug);
            showAlert(`Thank you for intersting for our ${delivery_mode} mode. A member of our team will be in touch shortly to assist you.`);
         }
       })
      // You can send form data to an API, or perform any other action
    };
   const GetCmsData = async () => {
      await GetCms('home').then(cmsresponse => {
          console.log("cmsresponse",cmsresponse);
         if (cmsresponse.status===200) {
          setCmsMode(cmsresponse.data);
          setCmsPageData(JSON.parse(cmsresponse.data?.page_details));       
         }
      })
   }
   useEffect(() => {
      GetCmsData();
  }, [location,cmsData?.length]);
  return ( 
   <>

  <div>
   {props.categoryList.length>0 && < Header clsName=""  mataTitle={pageData?.meta_title || ''} metaDes={pageData?.meta_desc || ''} categoryList={props.categoryList} />}
      <div className="banner_wrapper" style={{ background: 'url("'+Properties.IMAGE_PATH+'public/gallery/banner_1.png")' }}>

         <div className='bansp'>
            <h2>We are AIINOX,your gateway to the future of AI focused learning.</h2>
            <p>At AiinoX, we are committed to transforming the way individuals learn and grow across various disciplines. Our platform fosters a dynamic community where learners and professionals converge to explore, innovate, and excel in a wide range of fields, from artificial intelligence to business, leadership, and beyond.</p>
         </div>

      </div>
      <Search />
      <div className="course_sec">
         <div className="container">
            <div className="heading">What We Provide</div>
            <div className="row">
            {deliveryData?.map((item) =>(
            <div className="col-lg-3 col-md-6 col-sm-12 crs-pnl crs-pnl">
                     <div className="courese_list" onClick={(event) => DeliveryClick(event,item.slug)}>
                        <img src={item.icon}/>
                     <div className="sub_heading">{item.delivery_mode.split(":")[0] + ":"}</div>
                     <div className="learn_more">{item.delivery_mode.split(":")[1]}</div>
                     <p  className="learn_more">Learn more <i className="fa-solid fa-arrow-right-long"></i></p>
                  </div>
               </div>
                 ))
                } 
               
           
                  
            
        

            </div>
         </div>
      </div>
      <div className="why_sec" id="aboutus">
         <div className="container">
            <div className="small_heading">ABOUT US</div>
            <div className="heading">Why Choose AiinoX</div>
            <div className="row">
               <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="why_txt">
                     <p>At AIINOX, we go beyond the conventional narratives of technology replacing humans. We believe in the power of people leveraging AI and other emerging disciplines to <strong>innovate, transform, and solve real-world challenges.</strong> Our mission is not just to train coders or technical experts but to <strong> empower a new generation of forward-thinking professionals, leaders, and changemakers</strong>  across various industries.</p>
                     <p>Founded on the principle that education should be <strong>accessible, practical, and future-focused</strong>, AiinoX offers a curriculum at the cutting edge of innovation. Whether in <strong>Artificial Intelligence, Business, Leadership, or beyond,</strong> our courses equip learners with the skills, knowledge, and strategic mindset to navigate complex industries and drive meaningful impact.</p>
                     <p>As we stand on the edge of a new era of technological and business transformation, AiinoX is <strong> leading the way</strong>  in professional training. We are not just preparing individuals for the future but shaping it. <strong> Join us on this journey, and let's unlock limitless potential together.</strong> </p>
                     
                  </div>
               </div>
               <div className="col-lg-6 col-md-12 col-sm-12">
                  <figure>
                     <img src={Properties.IMAGE_PATH+`public/gallery/AI_MULTI PROCESSES.png`} alt="" />
                  </figure>
               </div>
            </div>
         </div>
      </div>
      <div className="anatomy_wrap">
         <div className="container">
            <div className="heading">The Anatomy of AiinoX</div>
            <div className="main_sub_heading">Select a character below to understand its wider meaning</div>
            <div className="row">
               <div className="col-md-2 col-sm-12">
                  <div className="box">
                     <div className="content_img img">
                        <img src={Properties.IMAGE_PATH+`public/gallery/A_OFF.svg`} alt="hover effect"/>
                        <img src={Properties.IMAGE_PATH+`public/gallery/A_ON.svg`} alt="hover effect"/>
                        <div className="img-text">
                           <div className="an_heading">Artificial</div>
                           <p>Emphasises focus on Artificial Intelligence, the cornerstone of our training platform.</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-2 col-sm-12">
                  <div className="box">
                     <div className="content_img img">
                        <img src={Properties.IMAGE_PATH+`public/gallery/I_OFF.svg`} alt="hover effect"/>
                        <img src={Properties.IMAGE_PATH+`public/gallery/I_ON.svg`} alt="hover effect"/>
                        <div className="img-text">
                           <div className="an_heading">Intelligence</div>
                           <p>Underscores the goal to enhance understanding and capabilities in intelligent systems.</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-2 col-sm-12">
                  <div className="box">
                     <div className="content_img img">
                        <img src={Properties.IMAGE_PATH+`public/gallery/I_OFF.svg`} alt="hover effect"/>
                        <img src={Properties.IMAGE_PATH+`public/gallery/I_ON.svg`} alt="hover effect"/>
                        <div className="img-text">
                           <div className="an_heading">Innovation</div>
                           <p>Commitment to pioneering new methods, ideas, and advancements within the AI sector.</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-2 col-sm-12">
                  <div className="box">
                     <div className="content_img img">
                        <img src={Properties.IMAGE_PATH+`public/gallery/N_OFF.svg`} alt="hover effect"/>
                        <img src={Properties.IMAGE_PATH+`public/gallery/N_ON.svg`} alt="hover effect"/>
                        <div className="img-text">
                           <div className="an_heading">Networking</div>
                           <p>Networking in AI, including neural networks and professional connections in the field.</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-2 col-sm-12">
                  <div className="box">
                     <div className="content_img img">
                        <img src={Properties.IMAGE_PATH+`public/gallery/O_OFF.svg`} alt="hover effect"/>
                        <img src={Properties.IMAGE_PATH+`public/gallery/O_ON.svg`} alt="hover effect"/>
                        <div className="img-text">
                           <div className="an_heading">Optimisation</div>
                           <p>Optimising processes, algorithms, and systems within AI for peak performance.</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-md-2 col-sm-12">
                  <div className="box">
                     <div className="content_img img">
                        <img src={Properties.IMAGE_PATH+`public/gallery/X_OFF.svg`} alt="hover effect" />
                        <img src={Properties.IMAGE_PATH+`public/gallery/X_ON.svg`} alt="hover effect" />
                        <div className="img-text">
                           <div className="an_heading">eXcellence</div>
                           <p>Denotes excellence in education, training, and research, setting high standards in the industry.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <section>
         <div className="container">
            <div className="grid-flex" id="ourmission">
               <div className="col col-image">
                  <img src={Properties.IMAGE_PATH+`public/gallery/industrial-factory.jpg`} alt='' />
               </div>
               <div className="col col-text">
                  <div className="Aligner-item">
                     <div className="small_heading">Our Mission:</div>
                     <div className="heading">Empowering Learning Across Industries</div>
                     <div className="why_txt">
                        <p>At AiinoX, we aim to <strong>democratise access to high-quality education</strong> across <strong>Artificial Intelligence and a wide range of professional disciplines</strong>. We are committed to <strong>empowering individuals and organisations</strong> by providing <strong>comprehensive, industry-leading training</strong> that equips learners with the skills, knowledge, and strategic insights needed to excel in today's dynamic world.</p>
                        <p>Through <strong>hands-on learning experiences, expert-led instruction, and cutting-edge curricula,</strong> we nurture <strong> innovators, leaders, and forward-thinkers</strong> who will drive progress and shape the future not just in AI but also in <strong> business, leadership, technology, and beyond.</strong></p>
                     </div>
                  </div>
               </div>
            </div>
            <div className="grid-flex" id="ourvision">
               <div className="col col-image" >
                   <img src={Properties.IMAGE_PATH+`public/gallery/industrial-factory_two.jpg`} alt='' />
               </div>
               <div className="col col-text2 col-left">
                  <div className="Aligner-item">
                     <div className="small_heading">Our Vision</div>
                     <div className="heading">Leading the Way in AI Innovation</div>
                     <div className="why_txt">
                        <p>Our vision is to be recognised as the premier global institution for AI and new technologies training, where learners from all backgrounds can achieve excellence. We envision a world where the transformative power of AI is accessible to everyone, fostering a vibrant community of tech enthusiasts and professionals who are creatively curious and prepared to tackle the challenges of tomorrow and leverage opportunities for positive impact. We aspire to ignite a lifelong passion for learning and innovation, paving the way for advancements that benefit humanity.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div className="business_list">
         <div className="container">
            <div className="small_heading">Our Values</div>
            <div className="heading">What Our Business Stands For</div>
            <ul className="bis-list">
               <li>
                  <img src={Properties.IMAGE_PATH+`public/gallery/LIGHTBULB ICON.svg`} alt=""/>
                  <div className="sub_heading">INNOVATION</div>
                  <p>Embracing constant learning, pushing boundaries, and exploring new frontiers in AI and technology.</p>
               </li>
               <li>
                  <img src={Properties.IMAGE_PATH+`public/gallery/VIDEOCONFERENCE ICON.svg`} alt="" />
                  <div className="sub_heading">INCLUSION</div>
                  <p>Fostering a diverse and welcoming community where everyone can thrive in the tech world.</p>
               </li>
               <li>
                  <img src={Properties.IMAGE_PATH+`public/gallery/ROSETTE ICON.svg`} alt="" />
                  <div className="sub_heading">INTEGRITY</div>
                  <p>Maintaining the highest ethical standards in AI development and application, prioritising human well-being.</p>
               </li>
               <li>
                  <img src={Properties.IMAGE_PATH+`public/gallery/BRAIN ICON.svg`} alt="" />
                  <div className="sub_heading">COLLABORATION</div>
                  <p>Building bridges across disciplines and backgrounds, leveraging collective intelligence to drive meaningful change.</p>
               </li>
               <li>
                  <img src={Properties.IMAGE_PATH+`public/gallery/DIGITAL GLOBE ICON.svg`} alt="" />
                  <div className="sub_heading">IMPACT</div>
                  <p>Focusing on solutions that address real-world challenges and contribute to a positive, sustainable future.</p>
               </li>
            </ul>
         </div>
      </div>
      <section id="testimonial">
         <div className="container">
            <div className="testimonial-section" data-aos="zoom-in">
               <OwlCarousel
               items={1}
               loop={true}
               nav={false}
               autoplay={true}
               dots={false}
               
               className="owl-carousel owl-theme">
                  <div className="each-testi global-content">
                     <span>
                     <img src={Properties.IMAGE_PATH+`public/gallery/QUOTMARKS.svg`} alt="" />
                     </span>
                     <p>
                        As the Director of AiinoX, I am driven by a passion for empowering individuals with the knowledge and skills to excel in the rapidly evolving field of artificial intelligence. At AiinoX, we’re not just teaching AI; we’re shaping the future of technology and education, one learner at a time.
                     </p>
                     <h6>David C.W.A Carruthers, Managing Director</h6>
                  </div>
                  <div className="each-testi global-content">
                     <span>
                     <img src={Properties.IMAGE_PATH+`public/gallery/QUOTMARKS.svg`} alt="" />
                     </span>
                     <p>
                        As the Director of AiinoX, I am driven by a passion for empowering individuals with the knowledge and skills to excel in the rapidly evolving field of artificial intelligence. At AiinoX, we’re not just teaching AI; we’re shaping the future of technology and education, one learner at a time.
                     </p>
                     <h6>David C.W.A Carruthers, Managing Director</h6>
                  </div>
                  
               </OwlCarousel >
            </div>
         </div>
      </section>      
      <Footer classNm="footermaindiv" />
    </div>
    <DeliveryMode DeliveryData={deliveryData} DelSlug={deliveryStateSlug} handlePopupClose={handlePopupClose} formData={formData} isSubmitting={isSubmitting} handleSubmit={handleSubmit} handleInputChange={handleInputChange} DelState={deliveryState} />
    </>
  )
  
}



export default Home