import React,{useState,useEffect} from 'react'
import { Link, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import { createPortal } from 'react-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import NavDropdown from 'react-bootstrap/NavDropdown';
import Properties from '../properties.js';
import MiniCart from './MiniCart';
import { Helmet } from 'react-helmet';
import CategoryMegamenu from './CategoryMegamenu';
const Header = (props) => {
   const [data, setData] = useState([]);      
   const [country, setCountry] = useState(""); 
   const [catName, setCatName] = useState([]);   
   const [getcartCount, setcartCount] = useState(0);   
   const [getCurrency, setCurrency] = useState();
   const [getShowMinicartModal, setShowMinicartModal] = useState(false);   
   //console.log("Header loaded");
   const currency = localStorage.getItem('currency');
   //console.log("miniCart",props.miniCart);
   //console.log("getShowMinicartModal",getShowMinicartModal);
   const location = useLocation(); 
   useEffect(() => {
    setShowMinicartModal(props?.miniCart)
   },[props?.miniCart])
   //console.log(location.pathname);
      const fetchData = async () => {
         try {
           const response = await fetch(Properties.BASE_PATH+'venue');
           const jsonData = await response.json();
            if(jsonData.status===200){
               setData(jsonData.data); 
            }          
         } catch (error) {
           console.error('Error fetching data:', error);
         }
       };
      const CountryClk = (event) =>{
         const cntry = event.target.textContent;         
         setCountry(cntry.substring(0, 3).toUpperCase());
      }
      // const cartCount = () => {
      //    const cartCnt = JSON.parse(localStorage.getItem('cart'));
      //    const carthedCnt = ((cartCnt?.length)?cartCnt.length:(props.cartData?.length)?props.cartData.length:0)
      //    setcartCount(carthedCnt);
      // }
      const setSelectedCurrency = (value) => {
        localStorage.setItem('currency',value);
        setCurrency(value);
        if(props.currencycng)
          props.currencycng();
      }
      useEffect(() => {
        fetchData();
         setCatName(props.categoryList);     
         //cartCount();   
         setSelectedCurrency((currency?.length)?currency:'pound');
      }, []);

      // const miniCart = () => {
      //   //console.log("here");
      //   setShowMinicartModal(true);
      // }
      // const miniCartClose = () => {
      //   //console.log("miniCartCloseheader");
      //   setShowMinicartModal(false);
      //   if(props?.miniCartClose){
      //     props.miniCartClose();
      //   }
      // }
//console.log(props);
  return (
    <div>
      <Helmet>
        <title>{props.mataTitle}</title>
        <meta 
        name="description" 
        content={props.metaDes} 
        />
      </Helmet>
      

<Navbar collapseOnSelect expand="lg" className={"hed bg-body-tertiary " + props.clsName??''}>
      <Container fluid>
        <Navbar.Brand as={Link}  to="/">
        <img src={Properties.IMAGE_PATH+`public/gallery/logo.png`} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav__menu">
            {/* <Nav.Link as={Link} to="/#aboutus">About Us</Nav.Link> */}

            <NavDropdown title="About Us" id="basic-nav-dropdown" className='dpsec'>
              <NavDropdown.Item as={Link} to="/#ourmission">Our Mission</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/#ourvision">Our Vision</NavDropdown.Item>
            </NavDropdown>

            {/* <Nav.Link as={Link} to="/#ourmission">Our Mission</Nav.Link>
            <Nav.Link as={Link} to="/#ourvision">Our Vision</Nav.Link> */}
            <NavDropdown title="Courses" id="collapsible-nav-dropdown" className='megamenu'>
            {/* {console.log("catName===>",props.courseList)} */}
              <CategoryMegamenu courseList={props.categoryList} />

            </NavDropdown>
            {/* <NavDropdown className='locationnav'  title={`Location: ${country}`} id="collapsible-nav-dropdown">          

                  {data.map((item,index) => (
                    <NavDropdown.Item onClick={CountryClk}  key={index}>{item.venue_name}<span> ({item.short_name})</span></NavDropdown.Item>
                  ))}      
            
            </NavDropdown> */}
            {/* <Nav.Link href="/our-services">Our Services</Nav.Link> */}
            <NavDropdown title="Our Services" id="basic-nav-dropdown" className='dpsec'>
              <NavDropdown.Item as={Link} to="/ai-industrial-markets">Classroom Training</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/business-consultation">Business Consultation</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/contact">Contact</Nav.Link>
            {(location?.pathname!=="/cart") &&
            <>
            <Nav.Link href="#">

            {/* <Link className="bts" onClick={miniCart}>
              Cart <img src={Properties.IMAGE_PATH+`public/gallery/CARTICON.svg`} />
              <p>{(props.cartData?.length)?props.cartData.length:getcartCount}</p>
            </Link> */}
            </Nav.Link>
            
              <select className='selt'
                value={getCurrency} 
                onChange={e => setSelectedCurrency(e.target.value)}
              >
                <option value="euro">€</option>
                <option value="pound">£</option>
                <option value="doller">$</option>
              </select>
              </>
            }
          </Nav>
         
        </Navbar.Collapse>
      </Container>
    </Navbar>
      {/* <MiniCart miniCartShow = {getShowMinicartModal} miniCartClose={miniCartClose} /> */}
    </div>
  )
}

export default Header